import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`My work on the planet involves learning and creating in many disciplines in order to
find solutions that bridge and eliminate the silos of our modern day world,
building fundamentally different systems where compassion and trust are more accessible. `}</p>
    <p>{`My goal is to create companies that foster the evolution of humans, break the status quo, and push us into a new paradigm. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      